// ////////////////////////////スマホ/タブレット判別
var _ua = (function (u) {
  return {
    Tablet:
      (u.indexOf('windows') != -1 && u.indexOf('touch') != -1) ||
      u.indexOf('ipad') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') == -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1) ||
      u.indexOf('kindle') != -1 ||
      u.indexOf('silk') != -1 ||
      u.indexOf('playbook') != -1,
    Mobile:
      (u.indexOf('windows') != -1 && u.indexOf('phone') != -1) ||
      u.indexOf('iphone') != -1 ||
      u.indexOf('ipod') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') != -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1) ||
      u.indexOf('blackberry') != -1,
  };
})(window.navigator.userAgent.toLowerCase());
// if(_ua.Mobile){}

var osVer;
osVer = 'Android';

var ua = navigator.userAgent.toLowerCase();
var iPadFlag = false;
if (/android|ipod|ipad|iphone|macintosh/.test(ua) && 'ontouchend' in document) {
  iPadFlag = true;
}
// if (navigator.userAgent.indexOf(osVer)>0){
// }

var breakNum = 768;
var tabletNum = 1024;
// ////////////////////////////////////init
$(function () {
  setScroll();
  if ($('#wrapper').hasClass('home')) {
  }
  // ///////////
  if (!_ua.Mobile && !_ua.Tablet) {
    $('#wrapper').addClass('desktop');
    if (!$('#wrapper').hasClass('about')) {
      $('html, body').prop({
        scrollTop: 0,
      });
      if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1) {
        // ここにSafari用の記述
      } else {
      }
    }
  }
  // ///////////
});

$(window).on('load', function () {
  // heightLineGroup()
  judgeWinSize();
  setAcc();
  setLoaded();
  setGsap();
  setMainMenu();
  setVideo();
  setFancy();
  // setHeader()
  if (!_ua.Mobile) {
    setLocomotive();
    searchPosition();
    ctlMap();
  }
  if (!_ua.Mobile && !_ua.Tablet) {
  }
});

///fancyBox
function setFancy() {
  $('[data-fancybox]').fancybox({
    // toolbar: false,
    // zoom: false,
    buttons: [
      // 'slideShow',
      // 'fullScreen',
      // 'thumbs',
      'close',
    ],
    //		caption: function (instance, item) {
    //			return ($(this).next('figcaption').html())
    //		},
    // touch: false,
  });
}

// ////////////////////動画制御
function setVideo() {
  $('.movieWrap').each(function () {
    var target = $(this).find('.video');
    var video = target.get(0);
    var playBtn = $(this).find('.playBtn');
    playBtn.on('click', function () {
      video.play();
      video.setAttribute('controls', 'controls');
      target.addClass('stand');
      $(this).addClass('hide');
    });
    video.addEventListener(
      'playing',
      function () {
        playBtn.addClass('hide');
      },
      true
    );
    video.addEventListener(
      'pause',
      function () {
        // playBtn.removeClass('hide')
      },
      true
    );
    video.addEventListener(
      'ended',
      function () {
        // playBtn.removeClass('hide')
      },
      true
    );
    // video.addEventListener('canplaythrough', function () {
    //  playBtn.removeClass('hide')
    //  summary.removeClass('active')
    //  startScroll()
    // }, true)
    target
      .mouseover(function () {
        if (target.hasClass('stand')) {
          video.setAttribute('controls', 'controls');
          // summary.addClass('active')
        }
      })
      .mouseout(function () {
        if (target.hasClass('stand')) {
          video.setAttribute('controls', 'controls');
          // summary.removeClass('active')
        }
      });
  });
}

var current_scrollY;
// //////////////////////////メインメニュー
function setMainMenu() {
  $('.burgerMenu').on('click', function () {
    if (!$('.burgerMenu').hasClass('active')) {
      openFnc();
    } else {
      closeFnc();
    }
  });
  // $('#mainNav a').on('click', function (event) {
  //   event.stopPropagation()
  // })
  //
  $('#clickBlocker,.menuPanel .closeArea,.globalNav a').on('click', function (event) {
    event.stopPropagation();
    closeFnc();
  });

  $('.mainNav a').on('click', function (event) {
    event.stopPropagation();
  });

  var winW = $(window).width();
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    winW = $(window).width();
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearInterval(timer);
    }
    timer = setTimeout(function () {
      if (winW > tabletNum) {
        closeFnc();
      }
    }, 200);
  });
}
function openFnc() {
  current_scrollY = $(window).scrollTop();
  $('.burgerMenu').addClass('active');
  $('#wrapper').addClass('menuOpen');
  // $('#outerMenu').css('top', -current_scrollY);
  // setTimeout(function () {
  //   $('html, body').prop({
  //     scrollTop: 0,
  //   });
  // }, 100);
}

function closeFnc() {
  $('.burgerMenu').removeClass('active');
  $('#outerMenu').css('top', '');
  $('#wrapper').removeClass('menuOpen');
  // 検索補助
  // $('header .btnSubmit').addClass('search-url')
  // $('#menuPanel .btnSubmit').removeClass('search-url')
  // $('html, body').prop({
  //   scrollTop: current_scrollY,
  // });
}

// googleMapのスクロール制御
function ctlMap() {
  var map = $('.accessMap iframe');
  // あらかじめiframeにpointer-events:noneを掛け、マウスイベントを無効にしておく
  map.css('pointer-events', 'none');
  // 一度クリックされたらマウスイベントを有効にする
  $('.accessMap').click(function () {
    map.css('pointer-events', 'auto');
  });
  // iframeからマウスが離れたら再度pointer-events:noneを効かせる
  map.mouseout(function () {
    map.css('pointer-events', 'none');
  });
}

// /ポジション調べ
function searchPosition() {
  var winW = $(window).width();
  var timer = false;
  var currentWidth = window.innerWidth;

  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
    }, 200);
  });

  var searching;
  function startTimer() {
    searching = setInterval(function () {
      addingClass('#mainVis');
      addingClass('#theme_01');
      addingClass('#theme_02');
      addingClass('#works');
      addingClass('#artist');
      addingClass('#movie');
      addingClass('#gallery');
      addingClass('#contact');
    }, 100);
  }
  function stopTimer() {
    clearInterval(searching);
  }

  startTimer();
  // クラス付与
  function addingClass(target) {
    let posX = Number($(target)[0].style.transform.split(',')[12]).toFixed(0) * -1;
    let baseW = winW - winW * 0.3;
    if (winW >= 1280) {
      var gap = 2290;
    } else {
      var gap = winW * (2290 / 1280);
    }
    switch (target) {
      case '#mainVis':
        baseW = winW - winW * 0.3;
        if (posX > baseW) {
          $(target).next().addClass('is-active');
          $('.scrollSec').removeClass('hide');
        }
        break;
      case '#theme_01':
        baseW = winW * 2 - winW * 0.3;
        if (posX > baseW) {
          $(target).next().addClass('is-active');
          $('.scrollSec').removeClass('hide');
        }
        break;
      case '#theme_02':
        baseW = winW * 3 - winW * 0.3;
        if (posX > baseW) {
          $(target).next().addClass('is-active');
          $('.scrollSec').removeClass('hide');
        }
        break;
      case '#works':
        baseW = winW * 4 - winW * 0.3;
        if (posX > baseW) {
          $(target).next().addClass('is-active');
          $('.scrollSec').removeClass('hide');
        }
        break;
      case '#artist':
        baseW = winW * 5 - winW * 0.3;
        if (posX > baseW) {
          $(target).next().addClass('is-active');
          $('.scrollSec').removeClass('hide');
        }
        break;
      case '#movie':
        baseW = winW * 5 + gap - winW;
        if (posX > baseW) {
          $(target).next().addClass('is-active');
          $(target).next().next().addClass('is-active');
          $('.scrollSec').removeClass('hide');
        }
        break;
      case '#gallery':
        baseW = winW * 5 + gap + 1008 / 4 - winW * 0.3;
        if (posX > baseW) {
          $(target).next().addClass('is-active');
          $('.scrollSec').addClass('hide');
        }
        break;
    }
  }
}

// //setLocomotive
function setLocomotive() {
  // var scroll = new LocomotiveScroll({
  //   el: document.querySelector('[data-scroll-container]'),
  //   smooth: true,
  //   direction: 'horizontal',
  //   multiplier: 1,
  //   horizontalGesture: true
  // })
  var scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    direction: 'horizontal',
    horizontalGesture: true,
    multiplier: 0.5,
    touchMultiplier: 8,
    smartphone: {
      smooth: true,
      direction: 'horizontal',
      // direction: 'vartical',
      // horizontalGesture: false
    },
    tablet: {
      smooth: true,
      direction: 'horizontal',
      // direction: 'vartical',
      horizontalGesture: true,
      gestureDirection: 'horizontal',
      breakpoint: 1025,
    },
  });

  // var winW = $(window).width()
  // if (winW <= breakNum) {
  //   scroll.stop()
  // }else {
  //   scroll.update()
  //   scroll.start()
  // }
  //
  var winW = $(window).width();
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
      if (winW <= breakNum) {
        scroll.stop();
      } else {
        scroll.update();
        scroll.start();
      }
    }, 200);
  });
}

// gsap
function setGsap() {
  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.refresh();
  ScrollTrigger.scrollerProxy('.site__main__contents', {
    scrollTop(value) {
      return arguments.length
        ? locoScroll.scrollTo(value, 0, 0)
        : locoScroll.scroll.instance.scroll.y;
    },
    getBoundingClientRect() {
      return {
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },

    pinType: document.querySelector('.horizonalContainer').style.transform ? 'transform' : 'fixed',
  });

  // スクロールでクラス付与
  document.querySelectorAll('#mainVis').forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      id: index + 1,
      start: 'top top',
      toggleClass: { targets: el, className: 'is-active' },
      once: false,
      // markers: true
      scroller: '.horizonalContainer',
    });
  });

  // スクロールでクラス付与
  document.querySelectorAll('.way,.js-way').forEach((el, index) => {
    ScrollTrigger.create({
      trigger: el,
      id: index + 1,
      start: 'top 50%',
      toggleClass: { targets: el, className: 'is-active' },
      once: true,
      // markers: true
      scroller: '.horizonalContainer',
    });
  });

  // スクロールでクラス付与
  document
    .querySelectorAll('.setSp .js-content,.setSp .sp-way,.setSp .sp-js-way')
    .forEach((el, index) => {
      ScrollTrigger.create({
        trigger: el,
        id: index + 1,
        start: 'top 50%',
        toggleClass: { targets: el, className: 'is-active-sp' },
        once: true,
      });
    });
}

// ////////////////////////////アコーディオン
function setAcc() {
  $('.accInfo .ttl').on('click', function () {
    $(this).toggleClass('active');
    $(this).next().stop().slideToggle('fast');
  });
}

// //////////////////////////////ロード完了
function setLoaded() {
  $('#wrapper').addClass('loaded');
  loadStart();
  // if ($('#wrapper').hasClass('home')) {
  //   loadStart()
  // }else {
  //   $('#wrapper').addClass('loadedDone')
  //   $('#wrapper').addClass('loadEnd')
  // }
}

function loadStart() {
  timer = setTimeout(function () {
    $('#wrapper').addClass('loadedDone');
  }, 400);
  timer = setTimeout(function () {
    $('#wrapper').addClass('loadEnd');
  }, 1500);
  // $('body,html').animate({
  //   scrollTop: 0
  // }, 0, 'swing')
}

// function setGnav () {
//   var i = 0
//   setInterval(function () {
//     $('#headNav li').eq(i).addClass('start')
//     i++
//     if (i >= $('#headNav li').length) i = 0
//   }, 100)
// }

// ////////////ウィンドウサイズを判別
function judgeWinSize() {
  var winW = $(window).width();
  if (winW > breakNum) {
    $('#wrapper').addClass('setPc');
  } else {
    $('#wrapper').addClass('setSp');
  }

  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
      if (winW > breakNum) {
        $('#wrapper').addClass('setPc');
        $('#wrapper').removeClass('setSp');
      } else {
        $('#wrapper').addClass('setSp');
        $('#wrapper').removeClass('setPc');
      }
    }, 200);
  });
}

// /////////////////////////////スムーススクロール
function setScroll() {
  // var headerHight = $('header').height() + 40
  // var headerHight = $('header').height()
  $('a[href^="#"]').click(function (e) {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top;

    $.when(
      $('html, body').animate(
        {
          scrollTop: position,
        },
        400,
        'swing'
      ),
      e.preventDefault()
    ).done(function () {
      var diff = target.offset().top;
      if (diff === position) {
      } else {
        $('html, body').animate(
          {
            scrollTop: diff,
          },
          10,
          'swing'
        );
      }
    });
  });
}

// ////////////////////////////////////ランドスケープ判定
var isLandscape = function () {
  if (window.innerHeight > window.innerWidth) {
    jQuery('body').addClass('portrait');
    jQuery('body').removeClass('landscape');
  } else {
    jQuery('body').addClass('landscape');
    jQuery('body').removeClass('portrait');
  }
};

if (_ua.Mobile) {
  jQuery(window).resize(function () {
    isLandscape();
  });
  isLandscape();
}

// //////////////////////////////高さ揃え
function heightLineGroup() {
  // setAutoHeight('#photoDiary .articleStyle_02 .inner')
  var winW = $(window).width();
  if (winW > breakNum) {
    // setAutoHeight('.setH_01 h2', 4)
    // setAutoHeight('.setH_01 h2')
    // setAutoHeight('#photoDiary .articleStyle_02 .inner')
    // setAutoHeight(".setH_01 .summary",3)
  } else {
    setAutoHeight('.setH_01 h2', 2);
    setAutoHeight('.setH_02 h2', 2);
    // setAutoHeight("#setH_08 h2",2)
  }

  startResize();
}

function setAutoHeight(target, count) {
  $(target).tile(count);
}

function startResize() {
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      heightLineGroup();
    }, 200);
  });
}
